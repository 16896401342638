<template>
  <!--应收项目 期货 -->
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <div
        class="erp-primary__btn"
        @click="showDetail(1)"
        v-if="(detailsData.orderStatus === 1 || detailsData.orderStatus === 4) && !detailsData.arAudit && hasPermission('erp:fmArap:saveArAudit')"
      >
        录入应收
      </div>

      <div
        class="erp-primary__btn"
        @click="showDetail(3)"
        v-if="
          (detailsData.arAudit || detailsData.modifyAudit) &&
          hasPermission('erp:fmArap:auditArap') &&
          (detailsData.orderStatus === 1 || detailsData.orderStatus === 4)
        "
      >
        审核
      </div>
      <div
        class="erp-primary__btn"
        @click="updateOrder"
        v-if="(detailsData.orderStatus === 1 || detailsData.orderStatus === 4) && hasPermission('erp:fmArap:saveArAudit')"
      >
        更新应收
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">查看应收项目</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="detailsData.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="detailsData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="件数" :value="detailsData.packageCountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="实际净重(kg)" :value="detailsData.actualWeightSumDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="供应商合同号" :value="detailsData.supplierContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="detailsData.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="detailsData.consigneeShortName"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- 应收项目（待审核） -->
    <receivablesAudit
      :data="detailsData.arAudit"
      :baseData="detailsData"
      :orderArapId="orderArapId"
      :version="version"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      @cancel="auditCancel"
      v-if="detailsData.arAudit"
    ></receivablesAudit>
    <!-- 修改应收项目（待审核） -->
    <receivablesEdit
      :baseData="detailsData"
      :data="detailsData.modifyAudit"
      :orderArapId="orderArapId"
      :version="version"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      @cancel="auditCancel"
      v-if="detailsData.modifyAudit"
    ></receivablesEdit>
    <!-- 应收项目（审核已通过） -->
    <receivablesPass
      :baseData="detailsData"
      :data="detailsData.arConfirmVo"
      :orderArapId="orderArapId"
      :version="version"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      @cancel="auditCancel"
      v-if="detailsData.arConfirmVo"
    ></receivablesPass>
    <!-- 应付项目（审核已通过） -->
    <!--审核页面-->
    <erp-drawer :visible="visible" v-if="type === 3" @close="closeDialog" erp class="no-padding fix-top-header" width="1370">
      <auditAccrued
        :isCope="1"
        :baseData="detailsData"
        :orderArapId="orderArapId"
        :version="version"
        :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
        @cancel="closeDialog"
      >
      </auditAccrued>
    </erp-drawer>
    <erp-drawer :visible="visible" v-if="type === 1" @close="closeDialog" erp class="no-padding fix-top-header" width="1170">
      <receivablesForm
        :baseData="detailsData"
        :orderArapId="orderArapId"
        :version="version"
        :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
        @cancel="closeDialog"
      >
      </receivablesForm>
    </erp-drawer>
  </div>
</template>

<script>
import receivablesAudit from './components/receivablesAudit.vue';
import receivablesPass from './components/receivablesPass.vue';
import receivablesEdit from './components/receivablesEdit.vue';
import auditAccrued from './components/auditAccrued.vue';
import receivablesForm from './components/receivablesForm.vue';
import { getRouterParams } from '@/utils/publicMethods';
export default {
  name: 'accruedDetail',
  components: {
    receivablesAudit,
    receivablesPass,
    receivablesEdit,
    auditAccrued,
    receivablesForm,
  },
  props: {},
  data() {
    return {
      detailsData: {},
      orderArapId: null, // 列表获取id
      version: null, // 列表获取版本
      type: null, // 切换不同显示弹窗
      visible: false, // 侧栏弹出显示
    };
  },
  methods: {
    updateOrder() {
      const id = this.detailsData.orderId;
      this.$erpConfirm('本操作用于更新系统自动计算的应收项目，例如代理费，代采费，保险费等等。确定要更新应收吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/order/orderArapCal',
            data: {
              orderId: id,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('更新成功', 3000);
                this.getCollectionData(this.orderArapId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    auditCancel(status) {
      if (status) {
        this.getCollectionData(this.orderArapId);
      }
    },
    closeDialog(status) {
      this.visible = false;
      this.auditCancel(status);
    },
    getCollectionData(id) {
      // 获取列表信息
      this.ajax({
        type: 'POST',
        url: '/malicrm/orderArap/queryOrderArapDetailById',
        data: {
          orderArapId: id,
          arapType: 1,
        },
        success: (res) => {
          if (res.code === 200) {
            this.detailsData = res.result;
            this.version = res.result.version;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    showDetail(type) {
      // 显示不同详情
      this.type = type;
      this.visible = true;
    },
    back() {
      // 返回
      this.$router.push('/erp/financial/accrued');
    },
  },
  created() {
    // 所有详情都可参照这个范式取传递数据
    const { id, version } = getRouterParams('accruedDetail');
    this.orderArapId = id;
    this.version = version;
    this.getCollectionData(id);
  },
};
</script>

<style lang="scss" scoped></style>
